<template>
	<div style="padding: 20px">
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="用户查询">
					<el-input v-model="searchForm.search" placeholder="请输入用户名/手机号"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button icon="el-icon-plus" @click="toEdit()">添加记录</el-button>
					<el-button type="primary" @click="exportCustomer">导出</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50">
			</el-table-column>
			
			<el-table-column prop="nursing_date" label="护理日期" width="100">
			</el-table-column>
			
			<el-table-column prop="consumer_care" label="消费项目">
			</el-table-column>
			
			<el-table-column prop="number" label="次数">
			</el-table-column>
			
			<el-table-column prop="product_materials" label="使用产品和物料领取单编号"  width="190">
			</el-table-column>
			
			<el-table-column label="客户确认">
				<template slot-scope="scope">
					<div class="text-green" v-if="scope.row.customer_yes==1">是√</div>
					<div class="text-gray" v-else>否×</div>
				</template>
			</el-table-column>
			
			<el-table-column label="老师确认">
				<template slot-scope="scope">
					<div class="text-green" v-if="scope.row.technician_yes==1">是√</div>
					<div class="text-gray" v-else>否×</div>
				</template>
			</el-table-column>
			
			<el-table-column prop="nursing_progress" label="护理项目进展" width="120">
			</el-table-column>
			
			<el-table-column prop="effect_response" label="效果反应">
			</el-table-column>
			
			<el-table-column prop="scoring" label="服务满意分数" width="120">
				<template slot-scope="scope">
					<div  v-if="scope.row.scoring==1">非常不满意</div>
					<div  v-if="scope.row.scoring==2">不满意</div>
					<div  v-if="scope.row.scoring==3">一般</div>
					<div  v-if="scope.row.scoring==4">满意</div>
					<div  v-if="scope.row.scoring==5">非常满意</div>
				</template>
				
			</el-table-column>
			
			
			<el-table-column prop="remark" label="备注">
			</el-table-column>


			<el-table-column fixed="right" label="操作" width="150">
				<template slot-scope="scope">
					<el-button @click="toEdit(scope.row)" size="mini">编辑</el-button>
					<el-popconfirm title="确定删除吗？" @confirm="del(scope.row)">
						<el-button slot="reference" size="mini">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				tableData: [], // 数据
				searchForm: {
					search: '',
					page: 1, // 否 int 页码
					page_size: 10, // 否 int 页脚
				},
				total: 0,
			};
		},
		mounted() {
			// 查询数据
			this.search()
		},
		methods: {
			search() {
				this.loading = true;
				this.$api.tablewx.getCustomerCareList(this.searchForm).then(res => {
					this.total = res.data.total;
					this.tableData = res.data.data;
					this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
				this.search()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
			toEdit(item) {
				if (item) {
					this.$router.push(`/tables/customerCare/edit/${item.id}`)
				} else {
					this.$router.push(`/tables/customerCare/edit`)
				}
			},
			//导出数据
			exportCustomer(){
				
				this.$api.tablewx.exportCustomerCareList(this.searchForm).then(res => {
					let a = document.createElement('a');
					let blob = new Blob([res], {
						type: "application/vnd.ms-excel"
					});
					let objectUrl = URL.createObjectURL(blob);
					a.setAttribute("href", objectUrl);
					a.setAttribute("download", '购买产品记录导出.xls');
					a.click();
				})
			},
			del(item) {
				const loading = this.$loading()
				this.$api.tablewx.delCustomerCareList({
					id: item.id
				}).then(res => {
					loading.close()
					if (res.code == 200) {
						this.$message.success(`操作成功`);
						this.search()
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					loading.close()
					this.$alert(err);
				});
			},
			
			// publish(item) {
			// 	let status = item.status;
			// 	item.status = item.status == 1 ? 0 : 1;
			// 	this.$api.project.save({
			// 		id: item.id,
			// 		status: item.status
			// 	}).then(res => {
			// 		if (res.code == 200) {
			// 			this.$message.success('操作成功')
			// 		} else {
			// 			this.$message.error(res.msg)
			// 			item.status = status;
			// 		}
			// 	}).catch(err => {
			// 		item.status = status;
			// 		this.$alert(err)
			// 	})
			// }
		},
	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}
</style>
